import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type11FileUrlService } from "./type11file.url.service";
import { Type11File } from "./Type11File";

export class Type11FileTransformator extends Transformator<Type11File> {

    async transformFromServer(rawType11File: any, localType11File?: Type11File): Promise<Type11File> {
        let result: Type11File = localType11File ? localType11File : new Type11File();
        result = Object.assign(result, rawType11File);
        return result;
    }

    transformToServer(type11File: Type11File): any {
        let result: any = Object.assign({}, type11File);
        return result;
    }
}

export class Type11FileService extends DataService<Type11File> {

    constructor(http: ApiClient, type11FileUrlService: Type11FileUrlService, type11FileTransformator: Type11FileTransformator) {
        super(http, type11FileUrlService, type11FileTransformator, "code");
    }
}

export class CachingType11FileService extends CachingDataService<Type11File> {

    constructor(http: ApiClient, type11FileUrlService: Type11FileUrlService, type11FileTransformator: Type11FileTransformator) {
        super(false, http, type11FileUrlService, type11FileTransformator, "code");
    }
}
