import { Type4Fraction, Type4FractionType } from "./Type4Fraction";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type4-fraction-table",
    styleUrls: ["./type4Fraction.table.scss"],
    templateUrl: "./type4Fraction.table.html"
})
export class Type4FractionTable extends PagingTableComponent<Type4Fraction> {

    @Input() title: string;

    Type4Fraction = Type4Fraction;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type4FractionType, Type4Fraction.columnInfo, modalTab || tab || deckTab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type4Fraction.valueListFieldKeys, Type4Fraction.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type4Fraction.valueListArrayKeys, Type4Fraction.getValueListArrayValues);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type4Fraction) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type4Fraction.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type4Fraction.getValueListArrayValues);
    }
}
