import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type13Code, Type13CodeType } from "./Type13Code";
import { Type13CodeTable } from "./type13Code.table";

@Component({
    selector: "spec-app-type13-code-select",
    templateUrl: "./type13Code.select.html",
    styleUrls: ["./type13Code.select.scss"]
})
export class Type13CodeSelect extends SelectWindow<Type13Code> {

    @ViewChild("table", { static: false }) table: Type13CodeTable;

    constructor(modalService: ModalService) {
        super(modalService, Type13CodeType, "head1", "code");
    }
}
