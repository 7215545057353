import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator, Error, Validator, createMaxLengthValidator, createExcludeValidator, createMinLengthValidator, createMinArrayLengthValidator, createMaxArrayValueLengthValidator, createMaxArrayLengthValidator, createMinArrayValueLengthValidator, createArrayExcludeValidator } from "@xo/client-common";
import { Type1String } from "./Type1String";

export function createType1StringValidators() {
    return [
        alma(),
        ...createRequiredValidators<Type1String>(["simpleField", "valueListField", "validatedField"]),
        createMaxLengthValidator("simpleField", 100),
        createMinLengthValidator("validatedField", 3),
        createMaxLengthValidator("validatedField", 8),
        createExcludeValidator("validatedField", ["wrong"]),
        createRegexpValidator('validatedField', /^[a-zA-Z]+$/, "Validated Field should contain only [a-zA-Z]+!"),
        createMinArrayLengthValidator("arrayField", 1),
        createMaxArrayValueLengthValidator("arrayField", 100),
        createMinArrayLengthValidator("valueListArray", 1),
        createMinArrayLengthValidator("validatedArrayField", 1),
        createMaxArrayLengthValidator("validatedArrayField", 3),
        createRegexpArrayValidator('validatedArrayField', /^[a-zA-Z]+$/, "Validated Array Field should contain only [a-zA-Z]+!"),
        createMinArrayValueLengthValidator("validatedArrayField", 3),
        createMaxArrayValueLengthValidator("validatedArrayField", 8),
        createArrayExcludeValidator("validatedArrayField", ["wrong"])
    ];
}

function alma() {
    return <Validator<Type1String>>{
        fieldName: null,
        validate: (object) => {
            if (object.simpleField == "alma") {
                return [new Error(null, "alma error")];
            } else {
                return [];
            }
        }
    };
}
