import { Component, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { SystemDashboard, SystemDashboardType } from "./SystemDashboard";
import { SystemDashboardService } from "./SystemDashboard.service";

@Component({
  selector: "specapp-system-dashboard",
  templateUrl: "./system-dashboard.page.html",
  styleUrls: ["./system-dashboard.page.scss"]
})
export class SystemDashBoardPage extends ObjectWindow<SystemDashboard, SystemDashboardService> {

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(SystemDashboardType), toastrService, [], changeDetectorRef);
  }

  setupNewObject(rawConfig) {
    let config = Object.assign(new SystemDashboard(), rawConfig);
    return config;
  }
}
