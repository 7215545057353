import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator } from "@xo/client-common";
import { Type9Specials } from "./Type9Specials";

export function createType9SpecialsValidators() {
    return [
        ...createRequiredValidators<Type9Specials>(["emailField", "emailArray", "passwordField", "passwordArray", "phoneField", "phoneArray"]),
        createRegexpValidator('emailField', /\S+@\S+[\.\S+]/, "Email Field should contain only \S+@\S+[\.\S+]!"),
        createRegexpArrayValidator('emailArray', /\S+@\S+[\.\S+]/, "Email Array should contain only \S+@\S+[\.\S+]!"),
        createRegexpValidator('phoneField', /^\+?[0-9()\-\ ]+$/, "Phone Field should contain only ^\+?[0-9()\-\ ]+$!"),
        createRegexpArrayValidator('phoneArray', /^\+?[0-9()\-\ ]+$/, "Phone Array should contain only ^\+?[0-9()\-\ ]+$!"),
    ];
}
