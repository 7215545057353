import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type2LongString, Type2LongStringType } from "./Type2LongString";
import { Type2LongStringTable } from "./type2LongString.table";

@Component({
    selector: "spec-app-type2-long-string-select",
    templateUrl: "./type2LongString.select.html",
    styleUrls: ["./type2LongString.select.scss"]
})
export class Type2LongStringSelect extends SelectWindow<Type2LongString> {

    @ViewChild("table", { static: false }) table: Type2LongStringTable;

    constructor(modalService: ModalService) {
        super(modalService, Type2LongStringType, "simpleField");
    }
}
