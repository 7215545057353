import { ApiClient, DataService, Transformator } from "@xo/services";
import { ConfigService, SystemUrlService } from "@xo/client-common";
import { SystemDashboard } from "./SystemDashboard";

export class SystemDashboardUrlService extends SystemUrlService {
  constructor(system: string, configService: ConfigService) {
    super(system, "SpecApp", "SystemDashboard", configService);
  }
}

export class SystemDashboardTransformator extends Transformator<SystemDashboard> {

  async transformFromServer(rawConfig: any, localConfig?: SystemDashboard): Promise<SystemDashboard> {
    let result: SystemDashboard = localConfig ? localConfig : new SystemDashboard();
    result = Object.assign(result, rawConfig);
    return result;
  }

  transformToServer(config: SystemDashboard): any {
    let result: any = Object.assign({}, config);
    return result;
  }
}

export class SystemDashboardService extends DataService<SystemDashboard> {

  constructor(http: ApiClient, configUrlService: SystemDashboardUrlService, configTransformator: SystemDashboardTransformator) {
    super(http, configUrlService, configTransformator, "id");
  }
}
