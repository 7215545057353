import { Component, ViewContainerRef } from "@angular/core";
import { ModalDialogService } from "@preeco-privacy/ngx-modal-dialog";
import { XOApp, ComponentService } from "@xo/client-common";
import { moduleNames as specAppNames } from "projects/specapp/src/specapp.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends XOApp {
  constructor(modalService: ModalDialogService,
    componentService: ComponentService,
    viewRef: ViewContainerRef) {

    super(modalService, componentService, viewRef);
    componentService.registerModule("SpecApp", specAppNames);
  }

}
