import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type8Timestamp, Type8TimestampType } from "./Type8Timestamp";
import { Type8TimestampTable } from "./type8Timestamp.table";

@Component({
    selector: "spec-app-type8-timestamp-select",
    templateUrl: "./type8Timestamp.select.html",
    styleUrls: ["./type8Timestamp.select.scss"]
})
export class Type8TimestampSelect extends SelectWindow<Type8Timestamp> {

    @ViewChild("table", { static: false }) table: Type8TimestampTable;

    constructor(modalService: ModalService) {
        super(modalService, Type8TimestampType, "simpleField");
    }
}
