import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type2Link, Type2LinkType } from "./Type2Link";
import { Type2LinkTable } from "./type2Link.table";

@Component({
    selector: "spec-app-type2-link-select",
    templateUrl: "./type2Link.select.html",
    styleUrls: ["./type2Link.select.scss"]
})
export class Type2LinkSelect extends SelectWindow<Type2Link> {

    @ViewChild("table", { static: false }) table: Type2LinkTable;

    constructor(modalService: ModalService) {
        super(modalService, Type2LinkType, "simpleField");
    }

    formatLabel(label) {
        return label?.label;
    }


}
