import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type3Number } from "./Type3Number";

@Component({
  selector: "spec-app-type3-number",
  templateUrl: "./type3Number.component.html",
  styleUrls: ["./type3Number.component.scss"]
})
export class Type3NumberComponent extends ObjectComponent {

  public Type3Number = Type3Number;
  @Input() public type3Number: Type3Number;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }

  convertNumber(convertable: any) {
    return parseInt(convertable.value, 10);
  }
}
