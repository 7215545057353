import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type9SpecialsUrlService } from "./type9specials.url.service";
import { Type9Specials } from "./Type9Specials";

export class Type9SpecialsTransformator extends Transformator<Type9Specials> {

    async transformFromServer(rawType9Specials: any, localType9Specials?: Type9Specials): Promise<Type9Specials> {
        let result: Type9Specials = localType9Specials ? localType9Specials : new Type9Specials();
        result = Object.assign(result, rawType9Specials);
        if (!result.emailArray) {
            result.emailArray = [];
        }
        if (!result.passwordArray) {
            result.passwordArray = [];
        }
        if (!result.phoneArray) {
            result.phoneArray = [];
        }
        return result;
    }

    transformToServer(type9Specials: Type9Specials): any {
        let result: any = Object.assign({}, type9Specials);
        return result;
    }
}

export class Type9SpecialsService extends DataService<Type9Specials> {

    constructor(http: ApiClient, type9SpecialsUrlService: Type9SpecialsUrlService, type9SpecialsTransformator: Type9SpecialsTransformator) {
        super(http, type9SpecialsUrlService, type9SpecialsTransformator, "id");
    }
}

export class CachingType9SpecialsService extends CachingDataService<Type9Specials> {

    constructor(http: ApiClient, type9SpecialsUrlService: Type9SpecialsUrlService, type9SpecialsTransformator: Type9SpecialsTransformator) {
        super(false, http, type9SpecialsUrlService, type9SpecialsTransformator, "id");
    }
}
