import { ObjectReference, Node, ColumnInfo } from "@xo/services";
import { Type2Link } from "../type2link/Type2Link";


export class Type10MixedReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type10MixedType = "Type10Mixed";

export class Type10Mixed extends Node {

    constructor() {
        super(Type10MixedType, "SpecApp/SimpleType10MixedComponent");
    }

    get label() {
        return this.stringField;
    }

    set label(label) {
        this.stringField = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("String Field", "stringField", "string", "None"),
        new ColumnInfo("String Array Field", "stringArrayField", "string", "None"),
        new ColumnInfo("Long String Field", "longStringField", "string", "None"),
        new ColumnInfo("Long String Array Field", "longStringArrayField", "string", "None"),
        new ColumnInfo("Number Field", "numberField", "number", "None"),
        new ColumnInfo("Number Array Field", "numberArrayField", "number", "None"),
        new ColumnInfo("Fraction Field", "fractionField", "number", "None"),
        new ColumnInfo("Fraction Array Field", "fractionArrayField", "number", "None"),
        new ColumnInfo("Boolean Field", "booleanField", "boolean", "None"),
        new ColumnInfo("Boolean Array Field", "booleanArrayField", "boolean", "None"),
        new ColumnInfo("Date Field", "dateField", "Date", "None"),
        new ColumnInfo("Date Array Field", "dateArrayField", "Date", "None"),
        new ColumnInfo("Time Field", "timeField", "string", "None"),
        new ColumnInfo("Time Array Field", "timeArrayField", "string", "None"),
        new ColumnInfo("Timestamp Field", "timestampField", "Date", "None"),
        new ColumnInfo("Timestamp Array Field", "timestampArrayField", "Date", "None"),
        new ColumnInfo("File 1", "file1", "File", "None"),
        new ColumnInfo("File 2", "file2", "File", "None"),
    ];

    public id: number;
    public stringField: string;
    public stringArrayField: string[] = [];
    public longStringField: string;
    public longStringArrayField: string[] = [];
    public numberField: number;
    public numberArrayField: number[] = [];
    public fractionField: number;
    public fractionArrayField: number[] = [];
    public booleanField: boolean;
    public booleanArrayField: boolean[] = [];
    public dateField: Date;
    public dateArrayField: Date[] = [];
    public timeField: string;
    public timeArrayField: string[] = [];
    public timestampField: Date;
    public timestampArrayField: Date[] = [];
    public file1: File | string;
    public file2: File | string;
    public type2Links: Type2Link[] = [];
}
