import { ObjectReference, Node, ColumnInfo } from "@xo/services";

export const valueListFieldValues = new Map<boolean, string>();
valueListFieldValues.set(true, "Igen");
valueListFieldValues.set(false, "Nem");

export const valueListArrayValues = new Map<boolean, string>();
valueListArrayValues.set(true, "Igen");
valueListArrayValues.set(false, "Nem");


export class Type5BooleanReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type5BooleanType = "Type5Boolean";

export class Type5Boolean extends Node {

    constructor() {
        super(Type5BooleanType, "SpecApp/SimpleType5BooleanComponent");
    }

    get label() {
        return "" + this.simpleField;
    }

    set label(label) {
        this.simpleField = "true" === label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "boolean", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "booleanSelect", "None"),
        new ColumnInfo("Validated Field", "validatedField", "boolean", "None"),
        new ColumnInfo("Array Field", "arrayField", "boolean", "None"),
        new ColumnInfo("ValueList Array", "valueListArray", "booleanSelect", "None"),
        new ColumnInfo("Validated Array Field", "validatedArrayField", "boolean", "None"),
    ];

    public static valueListFieldKeys: boolean[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: boolean): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: boolean[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: boolean): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: boolean;
    public valueListField: boolean;
    public validatedField: boolean;
    public arrayField: boolean[] = [];
    public valueListArray: boolean[] = [];
    public validatedArrayField: boolean[] = [];
}
