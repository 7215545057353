import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type11File, Type11FileType } from "./Type11File";
import { Type11FileService } from "./type11File.service";
import { Type11FileComponent } from "./type11File.component";
import { createType11FileValidators } from "./Type11FileValidation";

@Component({
  selector: "spec-app-type11-file-page",
  templateUrl: "./type11File-page.component.html",
  styleUrls: ["./type11File-page.component.scss"]
})
export class Type11FilePage extends ObjectWindow<Type11File, Type11FileService> {

  @ViewChild("component", { static: false }) component: Type11FileComponent;

  Type11File = Type11File;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type11FileType) as Type11FileService, toastrService, createType11FileValidators(), changeDetectorRef);
  }

  setupNewObject(rawType11File) {
    let type11File = Object.assign(new Type11File(), rawType11File);
    return type11File;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
