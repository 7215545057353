import { ObjectReference, Node, ColumnInfo } from "@xo/services";
import { Type1BaseReference } from "../type1base/Type1Base";
import { Type10Mixed } from "../type10mixed/Type10Mixed";


export class Type2LinkReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type2LinkType = "Type2Link";

export class Type2Link extends Node {

    constructor() {
        super(Type2LinkType, "SpecApp/SimpleType2LinkComponent");
    }

    get label() {
        return !!this.simpleField ? this.simpleField.label : "";
    }

    set label(label) {
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "stringLink", "None"),
        new ColumnInfo("Validated Field", "validatedField", "stringLink", "None"),
        new ColumnInfo("Array Field", "arrayField", "stringLink", "None"),
        new ColumnInfo("Mixed", "mixed", "stringLink", "None"),
    ];

    public id: number;
    public simpleField: Type1BaseReference;
    public validatedField: Type1BaseReference;
    public arrayField: Type1BaseReference[] = [];
    public mixed: Type10Mixed = new Type10Mixed();
}
