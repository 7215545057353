import { Type1Base, Type1BaseType } from "./Type1Base";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";
import { Type1BaseService } from "./type1Base.service";

@Component({
    selector: "spec-app-type1-base-table",
    styleUrls: ["./type1Base.table.scss"],
    templateUrl: "./type1Base.table.html"
})
export class Type1BaseTable extends PagingTableComponent<Type1Base> {

    @Input() title: string;

    Type1Base = Type1Base;


    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type1BaseType, Type1Base.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type1Base) {
        let classes: any = { objects: true };
        return classes;
    }

    async action() {
        await (this.tab.getService(Type1BaseType) as Type1BaseService).action();
    }
}
