import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type7TimeUrlService } from "./type7time.url.service";
import { Type7Time } from "./Type7Time";

export class Type7TimeTransformator extends Transformator<Type7Time> {

    async transformFromServer(rawType7Time: any, localType7Time?: Type7Time): Promise<Type7Time> {
        let result: Type7Time = localType7Time ? localType7Time : new Type7Time();
        result = Object.assign(result, rawType7Time);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type7Time: Type7Time): any {
        let result: any = Object.assign({}, type7Time);
        return result;
    }
}

export class Type7TimeService extends DataService<Type7Time> {

    constructor(http: ApiClient, type7TimeUrlService: Type7TimeUrlService, type7TimeTransformator: Type7TimeTransformator) {
        super(http, type7TimeUrlService, type7TimeTransformator, "id");
    }
}

export class CachingType7TimeService extends CachingDataService<Type7Time> {

    constructor(http: ApiClient, type7TimeUrlService: Type7TimeUrlService, type7TimeTransformator: Type7TimeTransformator) {
        super(false, http, type7TimeUrlService, type7TimeTransformator, "id");
    }
}
