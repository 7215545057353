import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator } from "@xo/client-common";
import { Type7Time } from "./Type7Time";

export function createType7TimeValidators() {
    return [
        ...createRequiredValidators<Type7Time>(["simpleField", "valueListField", "validatedField", "arrayField", "valueListArray", "validatedArrayField"]),
        createRegexpValidator('simpleField', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Simple Field should contain only ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$!"),
        createRegexpValidator('validatedField', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Validated Field should contain only ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$!"),
        createRegexpArrayValidator('arrayField', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Array Field should contain only ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$!"),
        createRegexpArrayValidator('validatedArrayField', /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Validated ArrayField should contain only ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$!"),
    ];
}
