import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type1StringUrlService } from "./type1string.url.service";
import { Type1String } from "./Type1String";

export class Type1StringTransformator extends Transformator<Type1String> {

    async transformFromServer(rawType1String: any, localType1String?: Type1String): Promise<Type1String> {
        let result: Type1String = localType1String ? localType1String : new Type1String();
        result = Object.assign(result, rawType1String);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type1String: Type1String): any {
        let result: any = Object.assign({}, type1String);
        return result;
    }
}

export class Type1StringService extends DataService<Type1String> {

    constructor(http: ApiClient, type1StringUrlService: Type1StringUrlService, type1StringTransformator: Type1StringTransformator) {
        super(http, type1StringUrlService, type1StringTransformator, "id");
    }
}

export class CachingType1StringService extends CachingDataService<Type1String> {

    constructor(http: ApiClient, type1StringUrlService: Type1StringUrlService, type1StringTransformator: Type1StringTransformator) {
        super(false, http, type1StringUrlService, type1StringTransformator, "id");
    }
}
