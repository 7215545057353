import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type5BooleanUrlService } from "./type5boolean.url.service";
import { Type5Boolean } from "./Type5Boolean";

export class Type5BooleanTransformator extends Transformator<Type5Boolean> {

    async transformFromServer(rawType5Boolean: any, localType5Boolean?: Type5Boolean): Promise<Type5Boolean> {
        let result: Type5Boolean = localType5Boolean ? localType5Boolean : new Type5Boolean();
        result = Object.assign(result, rawType5Boolean);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type5Boolean: Type5Boolean): any {
        let result: any = Object.assign({}, type5Boolean);
        return result;
    }
}

export class Type5BooleanService extends DataService<Type5Boolean> {

    constructor(http: ApiClient, type5BooleanUrlService: Type5BooleanUrlService, type5BooleanTransformator: Type5BooleanTransformator) {
        super(http, type5BooleanUrlService, type5BooleanTransformator, "id");
    }
}

export class CachingType5BooleanService extends CachingDataService<Type5Boolean> {

    constructor(http: ApiClient, type5BooleanUrlService: Type5BooleanUrlService, type5BooleanTransformator: Type5BooleanTransformator) {
        super(false, http, type5BooleanUrlService, type5BooleanTransformator, "id");
    }
}
