import { Type3Number, Type3NumberType } from "./Type3Number";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type3-number-table",
    styleUrls: ["./type3Number.table.scss"],
    templateUrl: "./type3Number.table.html"
})
export class Type3NumberTable extends PagingTableComponent<Type3Number> {

    @Input() title: string;

    Type3Number = Type3Number;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type3NumberType, Type3Number.columnInfo, modalTab || tab || deckTab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type3Number.valueListFieldKeys, Type3Number.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type3Number.valueListArrayKeys, Type3Number.getValueListArrayValues);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type3Number) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type3Number.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type3Number.getValueListArrayValues);
    }
}
