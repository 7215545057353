import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type1BaseUrlService } from "./type1base.url.service";
import { Type1Base } from "./Type1Base";

declare let window;

export class Type1BaseTransformator extends Transformator<Type1Base> {

    async transformFromServer(rawType1Base: any, localType1Base?: Type1Base): Promise<Type1Base> {
        let result: Type1Base = localType1Base ? localType1Base : new Type1Base();
        result = Object.assign(result, rawType1Base);
        return result;
    }

    transformToServer(type1Base: Type1Base): any {
        let result: any = Object.assign({}, type1Base);
        return result;
    }
}

export class Type1BaseService extends DataService<Type1Base> {

    constructor(http: ApiClient, private type1BaseUrlService: Type1BaseUrlService, type1BaseTransformator: Type1BaseTransformator) {
        super(http, type1BaseUrlService, type1BaseTransformator, "id");
    }

    async action() {
        return this.http.get(this.type1BaseUrlService.getActionUrl(), { withCredentials: true }).toPromise();
    }

    async createDocument(type1Base: Type1Base) {
        return this.http.get(this.type1BaseUrlService.getCreateDocumentUrl(type1Base), { withCredentials: true }).toPromise();
    }

    async stream(type1Base: Type1Base) {
        window.location = this.type1BaseUrlService.getStreamUrl(type1Base);
    }

    async download(type1Base: Type1Base, documentId: string) {
        window.location = this.type1BaseUrlService.getDownloadUrl(type1Base, documentId);
    }
}

export class CachingType1BaseService extends CachingDataService<Type1Base> {

    constructor(http: ApiClient, type1BaseUrlService: Type1BaseUrlService, type1BaseTransformator: Type1BaseTransformator) {
        super(false, http, type1BaseUrlService, type1BaseTransformator, "id");
    }
}
