import { Element } from "@xo/services";

export const AdminDashboardType = "AdminDashboard";

export class AdminDashboard extends Element {
  get id(): any {
    return null;
  }

  get label(): string {
    return "TODO";
  }

  set label(label: string) {
  }

  get classes(): string {
    return "";
  }

  set classes(cls: string) {
  }
}
