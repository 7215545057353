import { DataService, CachingDataService, Transformator, ApiClient, transformDateFromServer, transformTimestampToServer, transformDateArrayFromServer, transformTimestampArrayToServer } from "@xo/services";
import { Type8TimestampUrlService } from "./type8timestamp.url.service";
import { Type8Timestamp, valueListFieldValues, valueListArrayValues } from "./Type8Timestamp";
import { createValueListResolver } from "@xo/client-common";

export class Type8TimestampTransformator extends Transformator<Type8Timestamp> {

    async transformFromServer(rawType8Timestamp: any, localType8Timestamp?: Type8Timestamp): Promise<Type8Timestamp> {
        let result: Type8Timestamp = localType8Timestamp ? localType8Timestamp : new Type8Timestamp();
        result = Object.assign(result, rawType8Timestamp);
        result.simpleField = transformDateFromServer(rawType8Timestamp.simpleField);
        result.valueListField = createValueListResolver(valueListFieldValues)(transformDateFromServer(rawType8Timestamp.valueListField));
        result.validatedField = transformDateFromServer(rawType8Timestamp.validatedField);
        result.arrayField = transformDateArrayFromServer(rawType8Timestamp.arrayField);
        result.valueListArray = transformDateArrayFromServer(rawType8Timestamp.valueListArray).map(createValueListResolver(valueListArrayValues));
        result.validatedArrayField = transformDateArrayFromServer(rawType8Timestamp.validatedArrayField);
        return result;
    }

    transformToServer(type8Timestamp: Type8Timestamp): any {
        let result: any = Object.assign({}, type8Timestamp);
        result.simpleField = transformTimestampToServer(type8Timestamp.simpleField);
        result.valueListField = transformTimestampToServer(type8Timestamp.valueListField);
        result.validatedField = transformTimestampToServer(type8Timestamp.validatedField);
        result.arrayField = transformTimestampArrayToServer(type8Timestamp.arrayField);
        result.valueListArray = transformTimestampArrayToServer(type8Timestamp.valueListArray);
        result.validatedArrayField = transformTimestampArrayToServer(type8Timestamp.validatedArrayField);
        return result;
    }
}

export class Type8TimestampService extends DataService<Type8Timestamp> {

    constructor(http: ApiClient, type8TimestampUrlService: Type8TimestampUrlService, type8TimestampTransformator: Type8TimestampTransformator) {
        super(http, type8TimestampUrlService, type8TimestampTransformator, "id");
    }
}

export class CachingType8TimestampService extends CachingDataService<Type8Timestamp> {

    constructor(http: ApiClient, type8TimestampUrlService: Type8TimestampUrlService, type8TimestampTransformator: Type8TimestampTransformator) {
        super(false, http, type8TimestampUrlService, type8TimestampTransformator, "id");
    }
}
