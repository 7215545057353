import { HttpClient } from "@angular/common/http";
import { DataService } from "@xo/services";
import { ConfigService as CommonConfigService } from "@xo/client-common";
import { Type1String, Type1StringType } from "./type1string/Type1String";
import { Type1StringListWindowComponent } from "./type1string/type1String.list";
import { Type1StringTable } from "./type1string/type1String.table";
import { Type1StringPage } from "./type1string/type1String-page.component";
import { Type1StringComponent } from "./type1string/type1String.component";
import { Type1StringSelect } from "./type1string/type1String.select";
import { Type1StringTransformator, Type1StringService } from "./type1string/type1String.service";
import { Type1StringUrlService } from "./type1string/type1string.url.service";
import { Type2LongString, Type2LongStringType } from "./type2longstring/Type2LongString";
import { Type2LongStringListWindowComponent } from "./type2longstring/type2LongString.list";
import { Type2LongStringTable } from "./type2longstring/type2LongString.table";
import { Type2LongStringPage } from "./type2longstring/type2LongString-page.component";
import { Type2LongStringComponent } from "./type2longstring/type2LongString.component";
import { Type2LongStringSelect } from "./type2longstring/type2LongString.select";
import { Type2LongStringTransformator, Type2LongStringService } from "./type2longstring/type2LongString.service";
import { Type2LongStringUrlService } from "./type2longstring/type2longstring.url.service";
import { Type3Number, Type3NumberType } from "./type3number/Type3Number";
import { Type3NumberListWindowComponent } from "./type3number/type3Number.list";
import { Type3NumberTable } from "./type3number/type3Number.table";
import { Type3NumberPage } from "./type3number/type3Number-page.component";
import { Type3NumberComponent } from "./type3number/type3Number.component";
import { Type3NumberSelect } from "./type3number/type3Number.select";
import { Type3NumberTransformator, Type3NumberService } from "./type3number/type3Number.service";
import { Type3NumberUrlService } from "./type3number/type3number.url.service";
import { Type4Fraction, Type4FractionType } from "./type4fraction/Type4Fraction";
import { Type4FractionListWindowComponent } from "./type4fraction/type4Fraction.list";
import { Type4FractionTable } from "./type4fraction/type4Fraction.table";
import { Type4FractionPage } from "./type4fraction/type4Fraction-page.component";
import { Type4FractionComponent } from "./type4fraction/type4Fraction.component";
import { Type4FractionSelect } from "./type4fraction/type4Fraction.select";
import { Type4FractionTransformator, Type4FractionService } from "./type4fraction/type4Fraction.service";
import { Type4FractionUrlService } from "./type4fraction/type4fraction.url.service";
import { Type5Boolean, Type5BooleanType } from "./type5boolean/Type5Boolean";
import { Type5BooleanListWindowComponent } from "./type5boolean/type5Boolean.list";
import { Type5BooleanTable } from "./type5boolean/type5Boolean.table";
import { Type5BooleanPage } from "./type5boolean/type5Boolean-page.component";
import { Type5BooleanComponent } from "./type5boolean/type5Boolean.component";
import { Type5BooleanSelect } from "./type5boolean/type5Boolean.select";
import { Type5BooleanTransformator, Type5BooleanService } from "./type5boolean/type5Boolean.service";
import { Type5BooleanUrlService } from "./type5boolean/type5boolean.url.service";
import { Type6Date, Type6DateType } from "./type6date/Type6Date";
import { Type6DateListWindowComponent } from "./type6date/type6Date.list";
import { Type6DateTable } from "./type6date/type6Date.table";
import { Type6DatePage } from "./type6date/type6Date-page.component";
import { Type6DateComponent } from "./type6date/type6Date.component";
import { Type6DateSelect } from "./type6date/type6Date.select";
import { Type6DateTransformator, Type6DateService } from "./type6date/type6Date.service";
import { Type6DateUrlService } from "./type6date/type6date.url.service";
import { Type7Time, Type7TimeType } from "./type7time/Type7Time";
import { Type7TimeListWindowComponent } from "./type7time/type7Time.list";
import { Type7TimeTable } from "./type7time/type7Time.table";
import { Type7TimePage } from "./type7time/type7Time-page.component";
import { Type7TimeComponent } from "./type7time/type7Time.component";
import { Type7TimeSelect } from "./type7time/type7Time.select";
import { Type7TimeTransformator, Type7TimeService } from "./type7time/type7Time.service";
import { Type7TimeUrlService } from "./type7time/type7time.url.service";
import { Type8Timestamp, Type8TimestampType } from "./type8timestamp/Type8Timestamp";
import { Type8TimestampListWindowComponent } from "./type8timestamp/type8Timestamp.list";
import { Type8TimestampTable } from "./type8timestamp/type8Timestamp.table";
import { Type8TimestampPage } from "./type8timestamp/type8Timestamp-page.component";
import { Type8TimestampComponent } from "./type8timestamp/type8Timestamp.component";
import { Type8TimestampSelect } from "./type8timestamp/type8Timestamp.select";
import { Type8TimestampTransformator, Type8TimestampService } from "./type8timestamp/type8Timestamp.service";
import { Type8TimestampUrlService } from "./type8timestamp/type8timestamp.url.service";
import { Type9Specials, Type9SpecialsType } from "./type9specials/Type9Specials";
import { Type9SpecialsListWindowComponent } from "./type9specials/type9Specials.list";
import { Type9SpecialsTable } from "./type9specials/type9Specials.table";
import { Type9SpecialsPage } from "./type9specials/type9Specials-page.component";
import { Type9SpecialsComponent } from "./type9specials/type9Specials.component";
import { Type9SpecialsSelect } from "./type9specials/type9Specials.select";
import { Type9SpecialsTransformator, Type9SpecialsService } from "./type9specials/type9Specials.service";
import { Type9SpecialsUrlService } from "./type9specials/type9specials.url.service";
import { Type12RichText, Type12RichTextType } from "./type12richtext/Type12RichText";
import { Type12RichTextListWindowComponent } from "./type12richtext/type12RichText.list";
import { Type12RichTextTable } from "./type12richtext/type12RichText.table";
import { Type12RichTextPage } from "./type12richtext/type12RichText-page.component";
import { Type12RichTextComponent } from "./type12richtext/type12RichText.component";
import { Type12RichTextSelect } from "./type12richtext/type12RichText.select";
import { Type12RichTextTransformator, Type12RichTextService } from "./type12richtext/type12RichText.service";
import { Type12RichTextUrlService } from "./type12richtext/type12richtext.url.service";
import { Type1Base, Type1BaseType } from "./type1base/Type1Base";
import { Type1BaseListWindowComponent } from "./type1base/type1Base.list";
import { Type1BaseTable } from "./type1base/type1Base.table";
import { Type1BasePage } from "./type1base/type1Base-page.component";
import { Type1BaseComponent } from "./type1base/type1Base.component";
import { Type1BaseSelect } from "./type1base/type1Base.select";
import { Type1BaseTransformator, Type1BaseService } from "./type1base/type1Base.service";
import { Type1BaseUrlService } from "./type1base/type1base.url.service";
import { Type2Link, Type2LinkType } from "./type2link/Type2Link";
import { Type2LinkListWindowComponent } from "./type2link/type2Link.list";
import { Type2LinkTable } from "./type2link/type2Link.table";
import { Type2LinkPage } from "./type2link/type2Link-page.component";
import { Type2LinkComponent } from "./type2link/type2Link.component";
import { Type2LinkSelect } from "./type2link/type2Link.select";
import { Type2LinkTransformator, Type2LinkService } from "./type2link/type2Link.service";
import { Type2LinkUrlService } from "./type2link/type2link.url.service";
import { Type11File, Type11FileType } from "./type11file/Type11File";
import { Type11FileListWindowComponent } from "./type11file/type11File.list";
import { Type11FileTable } from "./type11file/type11File.table";
import { Type11FilePage } from "./type11file/type11File-page.component";
import { Type11FileComponent } from "./type11file/type11File.component";
import { Type11FileSelect } from "./type11file/type11File.select";
import { Type11FileTransformator, Type11FileService } from "./type11file/type11File.service";
import { Type11FileUrlService } from "./type11file/type11file.url.service";
import { Type10Mixed, Type10MixedType } from "./type10mixed/Type10Mixed";
import { Type10MixedListWindowComponent } from "./type10mixed/type10Mixed.list";
import { Type10MixedTable } from "./type10mixed/type10Mixed.table";
import { Type10MixedPage } from "./type10mixed/type10Mixed-page.component";
import { Type10MixedComponent } from "./type10mixed/type10Mixed.component";
import { Type10MixedSelect } from "./type10mixed/type10Mixed.select";
import { Type10MixedTransformator, Type10MixedService } from "./type10mixed/type10Mixed.service";
import { Type10MixedUrlService } from "./type10mixed/type10mixed.url.service";
import { Type13Code, Type13CodeType } from "./type13code/Type13Code";
import { Type13CodeListWindowComponent } from "./type13code/type13Code.list";
import { Type13CodeTable } from "./type13code/type13Code.table";
import { Type13CodePage } from "./type13code/type13Code-page.component";
import { Type13CodeComponent } from "./type13code/type13Code.component";
import { Type13CodeSelect } from "./type13code/type13Code.select";
import { Type13CodeTransformator, Type13CodeService } from "./type13code/type13Code.service";
import { Type13CodeUrlService } from "./type13code/type13code.url.service";
import { Type14AI, Type14AIType } from "./type14ai/Type14AI";
import { Type14AIListWindowComponent } from "./type14ai/type14AI.list";
import { Type14AITable } from "./type14ai/type14AI.table";
import { Type14AIPage } from "./type14ai/type14AI-page.component";
import { Type14AIComponent } from "./type14ai/type14AI.component";
import { Type14AISelect } from "./type14ai/type14AI.select";
import { Type14AITransformator, Type14AIService } from "./type14ai/type14AI.service";
import { Type14AIUrlService } from "./type14ai/type14ai.url.service";
import { UserDashBoardPage } from "./dashboard/user/user-dashboard.page";
import { UserDashBoardComponent } from "./dashboard/user/user-dashboard.component";
import { UserDashboardService, UserDashboardTransformator, UserDashboardUrlService } from "./dashboard/user/UserDashboard.service";
import { UserDashboard, UserDashboardType } from "./dashboard/user/UserDashboard";
import { AdminDashBoardPage } from "./dashboard/admin/admin-dashboard.page";
import { AdminDashBoardComponent } from "./dashboard/admin/admin-dashboard.component";
import { AdminDashboardService, AdminDashboardTransformator, AdminDashboardUrlService } from "./dashboard/admin/AdminDashboard.service";
import { AdminDashboard, AdminDashboardType } from "./dashboard/admin/AdminDashboard";
import { SystemDashBoardPage } from "./dashboard/system/system-dashboard.page";
import { SystemDashBoardComponent } from "./dashboard/system/system-dashboard.component";
import { SystemDashboardService, SystemDashboardTransformator, SystemDashboardUrlService } from "./dashboard/system/SystemDashboard.service";
import { SystemDashboard, SystemDashboardType } from "./dashboard/system/SystemDashboard";

export const SPECAPP = "SpecApp";

export let moduleNames = {
    Type1StringListWindowComponent,
    Type1StringPage,
    Type1StringSelect,
    Type2LongStringListWindowComponent,
    Type2LongStringPage,
    Type2LongStringSelect,
    Type3NumberListWindowComponent,
    Type3NumberPage,
    Type3NumberSelect,
    Type4FractionListWindowComponent,
    Type4FractionPage,
    Type4FractionSelect,
    Type5BooleanListWindowComponent,
    Type5BooleanPage,
    Type5BooleanSelect,
    Type6DateListWindowComponent,
    Type6DatePage,
    Type6DateSelect,
    Type7TimeListWindowComponent,
    Type7TimePage,
    Type7TimeSelect,
    Type8TimestampListWindowComponent,
    Type8TimestampPage,
    Type8TimestampSelect,
    Type9SpecialsListWindowComponent,
    Type9SpecialsPage,
    Type9SpecialsSelect,
    Type12RichTextListWindowComponent,
    Type12RichTextPage,
    Type12RichTextSelect,
    Type1BaseListWindowComponent,
    Type1BasePage,
    Type1BaseSelect,
    Type2LinkListWindowComponent,
    Type2LinkPage,
    Type2LinkSelect,
    Type11FileListWindowComponent,
    Type11FilePage,
    Type11FileSelect,
    Type10MixedListWindowComponent,
    Type10MixedPage,
    Type10MixedSelect,
    Type13CodeListWindowComponent,
    Type13CodePage,
    Type13CodeSelect,
    Type14AIListWindowComponent,
    Type14AIPage,
    Type14AISelect,
    UserDashBoardPage,
    AdminDashBoardPage,
    SystemDashBoardPage,
};

export let tabComponents = [
    Type1StringListWindowComponent, Type1StringPage, Type1StringSelect,
    Type2LongStringListWindowComponent, Type2LongStringPage, Type2LongStringSelect,
    Type3NumberListWindowComponent, Type3NumberPage, Type3NumberSelect,
    Type4FractionListWindowComponent, Type4FractionPage, Type4FractionSelect,
    Type5BooleanListWindowComponent, Type5BooleanPage, Type5BooleanSelect,
    Type6DateListWindowComponent, Type6DatePage, Type6DateSelect,
    Type7TimeListWindowComponent, Type7TimePage, Type7TimeSelect,
    Type8TimestampListWindowComponent, Type8TimestampPage, Type8TimestampSelect,
    Type9SpecialsListWindowComponent, Type9SpecialsPage, Type9SpecialsSelect,
    Type12RichTextListWindowComponent, Type12RichTextPage, Type12RichTextSelect,
    Type1BaseListWindowComponent, Type1BasePage, Type1BaseSelect,
    Type2LinkListWindowComponent, Type2LinkPage, Type2LinkSelect,
    Type11FileListWindowComponent, Type11FilePage, Type11FileSelect,
    Type10MixedListWindowComponent, Type10MixedPage, Type10MixedSelect,
    Type13CodeListWindowComponent, Type13CodePage, Type13CodeSelect,
    Type14AIListWindowComponent, Type14AIPage, Type14AISelect,
    UserDashBoardPage,
    AdminDashBoardPage,
    SystemDashBoardPage,
];

export let exports = [];

export let components = [
    Type1StringTable, Type1StringComponent,
    Type2LongStringTable, Type2LongStringComponent,
    Type3NumberTable, Type3NumberComponent,
    Type4FractionTable, Type4FractionComponent,
    Type5BooleanTable, Type5BooleanComponent,
    Type6DateTable, Type6DateComponent,
    Type7TimeTable, Type7TimeComponent,
    Type8TimestampTable, Type8TimestampComponent,
    Type9SpecialsTable, Type9SpecialsComponent,
    Type12RichTextTable, Type12RichTextComponent,
    Type1BaseTable, Type1BaseComponent,
    Type2LinkTable, Type2LinkComponent,
    Type11FileTable, Type11FileComponent,
    Type10MixedTable, Type10MixedComponent,
    Type13CodeTable, Type13CodeComponent,
    Type14AITable, Type14AIComponent,
    UserDashBoardComponent,
    AdminDashBoardComponent,
    SystemDashBoardComponent,
];

export let declarations = [
    ...components,
    ...tabComponents,
];

let systemServices = new Map<string, Map<string, DataService<any>>>();

export function createSpecAppServiceFactory(commonConfigService: CommonConfigService, http: HttpClient) {
    return function (systemName: string): Map<string, DataService<any>> {
        let serviceMap = systemServices.get(systemName);
        if (!!serviceMap) {
            return serviceMap;
        }

        serviceMap = new Map<string, DataService<any>>();

        let type1StringTransformator = new Type1StringTransformator();
        let type1StringUrlService = new Type1StringUrlService(systemName, commonConfigService);
        let type1StringService: DataService<Type1String> = new Type1StringService(http, type1StringUrlService, type1StringTransformator);
        serviceMap.set(Type1StringType, type1StringService);

        let type2LongStringTransformator = new Type2LongStringTransformator();
        let type2LongStringUrlService = new Type2LongStringUrlService(systemName, commonConfigService);
        let type2LongStringService: DataService<Type2LongString> = new Type2LongStringService(http, type2LongStringUrlService, type2LongStringTransformator);
        serviceMap.set(Type2LongStringType, type2LongStringService);

        let type3NumberTransformator = new Type3NumberTransformator();
        let type3NumberUrlService = new Type3NumberUrlService(systemName, commonConfigService);
        let type3NumberService: DataService<Type3Number> = new Type3NumberService(http, type3NumberUrlService, type3NumberTransformator);
        serviceMap.set(Type3NumberType, type3NumberService);

        let type4FractionTransformator = new Type4FractionTransformator();
        let type4FractionUrlService = new Type4FractionUrlService(systemName, commonConfigService);
        let type4FractionService: DataService<Type4Fraction> = new Type4FractionService(http, type4FractionUrlService, type4FractionTransformator);
        serviceMap.set(Type4FractionType, type4FractionService);

        let type5BooleanTransformator = new Type5BooleanTransformator();
        let type5BooleanUrlService = new Type5BooleanUrlService(systemName, commonConfigService);
        let type5BooleanService: DataService<Type5Boolean> = new Type5BooleanService(http, type5BooleanUrlService, type5BooleanTransformator);
        serviceMap.set(Type5BooleanType, type5BooleanService);

        let type6DateTransformator = new Type6DateTransformator();
        let type6DateUrlService = new Type6DateUrlService(systemName, commonConfigService);
        let type6DateService: DataService<Type6Date> = new Type6DateService(http, type6DateUrlService, type6DateTransformator);
        serviceMap.set(Type6DateType, type6DateService);

        let type7TimeTransformator = new Type7TimeTransformator();
        let type7TimeUrlService = new Type7TimeUrlService(systemName, commonConfigService);
        let type7TimeService: DataService<Type7Time> = new Type7TimeService(http, type7TimeUrlService, type7TimeTransformator);
        serviceMap.set(Type7TimeType, type7TimeService);

        let type8TimestampTransformator = new Type8TimestampTransformator();
        let type8TimestampUrlService = new Type8TimestampUrlService(systemName, commonConfigService);
        let type8TimestampService: DataService<Type8Timestamp> = new Type8TimestampService(http, type8TimestampUrlService, type8TimestampTransformator);
        serviceMap.set(Type8TimestampType, type8TimestampService);

        let type9SpecialsTransformator = new Type9SpecialsTransformator();
        let type9SpecialsUrlService = new Type9SpecialsUrlService(systemName, commonConfigService);
        let type9SpecialsService: DataService<Type9Specials> = new Type9SpecialsService(http, type9SpecialsUrlService, type9SpecialsTransformator);
        serviceMap.set(Type9SpecialsType, type9SpecialsService);

        let type12RichTextTransformator = new Type12RichTextTransformator();
        let type12RichTextUrlService = new Type12RichTextUrlService(systemName, commonConfigService);
        let type12RichTextService: DataService<Type12RichText> = new Type12RichTextService(http, type12RichTextUrlService, type12RichTextTransformator);
        serviceMap.set(Type12RichTextType, type12RichTextService);

        let type1BaseTransformator = new Type1BaseTransformator();
        let type1BaseUrlService = new Type1BaseUrlService(systemName, commonConfigService);
        let type1BaseService: DataService<Type1Base> = new Type1BaseService(http, type1BaseUrlService, type1BaseTransformator);
        serviceMap.set(Type1BaseType, type1BaseService);

        let type2LinkTransformator = new Type2LinkTransformator();
        let type2LinkUrlService = new Type2LinkUrlService(systemName, commonConfigService);
        let type2LinkService: DataService<Type2Link> = new Type2LinkService(http, type2LinkUrlService, type2LinkTransformator);
        serviceMap.set(Type2LinkType, type2LinkService);

        let type11FileTransformator = new Type11FileTransformator();
        let type11FileUrlService = new Type11FileUrlService(systemName, commonConfigService);
        let type11FileService: DataService<Type11File> = new Type11FileService(http, type11FileUrlService, type11FileTransformator);
        serviceMap.set(Type11FileType, type11FileService);

        let type10MixedTransformator = new Type10MixedTransformator();
        let type10MixedUrlService = new Type10MixedUrlService(systemName, commonConfigService);
        let type10MixedService: DataService<Type10Mixed> = new Type10MixedService(http, type10MixedUrlService, type10MixedTransformator);
        serviceMap.set(Type10MixedType, type10MixedService);

        let type13CodeTransformator = new Type13CodeTransformator();
        let type13CodeUrlService = new Type13CodeUrlService(systemName, commonConfigService);
        let type13CodeService: DataService<Type13Code> = new Type13CodeService(http, type13CodeUrlService, type13CodeTransformator);
        serviceMap.set(Type13CodeType, type13CodeService);

        let type14AITransformator = new Type14AITransformator();
        let type14AIUrlService = new Type14AIUrlService(systemName, commonConfigService);
        let type14AIService: DataService<Type14AI> = new Type14AIService(http, type14AIUrlService, type14AITransformator);
        serviceMap.set(Type14AIType, type14AIService);

        let userDashboardTransformator = new UserDashboardTransformator();
        let userDashboardUrlService = new UserDashboardUrlService(systemName, commonConfigService);
        let userDashboardService: DataService<UserDashboard> = new UserDashboardService(http, userDashboardUrlService, userDashboardTransformator);
        serviceMap.set(UserDashboardType, userDashboardService);

        let adminDashboardTransformator = new AdminDashboardTransformator();
        let adminDashboardUrlService = new AdminDashboardUrlService(systemName, commonConfigService);
        let adminDashboardService: DataService<AdminDashboard> = new AdminDashboardService(http, adminDashboardUrlService, adminDashboardTransformator);
        serviceMap.set(AdminDashboardType, adminDashboardService);

        let systemDashboardTransformator = new SystemDashboardTransformator();
        let systemDashboardUrlService = new SystemDashboardUrlService(systemName, commonConfigService);
        let systemDashboardService: DataService<SystemDashboard> = new SystemDashboardService(http, systemDashboardUrlService, systemDashboardTransformator);
        serviceMap.set(SystemDashboardType, systemDashboardService);

        systemServices.set(systemName, serviceMap);
        return serviceMap;
    };
}
