import { ApiClient, DataService, Transformator } from "@xo/services";
import { ConfigService, SystemUrlService } from "@xo/client-common";
import { UserDashboard } from "./UserDashboard";

export class UserDashboardUrlService extends SystemUrlService {
  constructor(system: string, configService: ConfigService) {
    super(system, "SpecApp", "UserDashboard", configService);
  }
}

export class UserDashboardTransformator extends Transformator<UserDashboard> {

  async transformFromServer(rawConfig: any, localConfig?: UserDashboard): Promise<UserDashboard> {
    let result: UserDashboard = localConfig ? localConfig : new UserDashboard();
    result = Object.assign(result, rawConfig);
    return result;
  }

  transformToServer(config: UserDashboard): any {
    let result: any = Object.assign({}, config);
    return result;
  }
}

export class UserDashboardService extends DataService<UserDashboard> {

  constructor(http: ApiClient, configUrlService: UserDashboardUrlService, configTransformator: UserDashboardTransformator) {
    super(http, configUrlService, configTransformator, "id");
  }
}
