import { Component } from "@angular/core";
import { createValidate, ValidationMessage } from "@xo/client-common";
import { AdminDashboard } from "./AdminDashboard";

@Component({
  selector: "specapp-admin-component",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"]
})
export class AdminDashBoardComponent {

  public validate: Function;
  public validationMessages: ValidationMessage[] = [];

  constructor() {
    this.validate = createValidate<AdminDashboard>([], this.validationMessages);
  }
}
