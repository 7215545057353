import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type1Base } from "./Type1Base";
import { ViewChild } from "@angular/core";
import { Type1BaseReference } from "../type1base/Type1Base";
import { PageService, FilterObject } from "@xo/client-common";
import { Type2LinkTable } from "../type2link/type2Link.table";
import { Type13CodeTable } from "../type13code/type13Code.table";

@Component({
  selector: "spec-app-type1-base",
  templateUrl: "./type1Base.component.html",
  styleUrls: ["./type1Base.component.scss"]
})
export class Type1BaseComponent extends ObjectComponent {

  public Type1Base = Type1Base;
  @Input() public type1Base: Type1Base;
  @Input() public title: string;

  @ViewChild("type2LinksWithSimpleField", { static: false }) type2LinksWithSimpleField: Type2LinkTable;

  @ViewChild("type2LinksWithValidatedField", { static: false }) type2LinksWithValidatedField: Type2LinkTable;

  @ViewChild("type2LinksWithArrayField", { static: false }) type2LinksWithArrayField: Type2LinkTable;

  @ViewChild("type13CodesWithBase", { static: false }) type13CodesWithBase: Type13CodeTable;

  @ViewChild("type13CodesWithBaseArray", { static: false }) type13CodesWithBaseArray: Type13CodeTable;


  constructor(public pageService: PageService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
    this.initFilters();
    this.reloadTables();
  }

  initFilters() {
    this.type2LinksWithSimpleField.filters = { simpleField: this.type1Base.id } as FilterObject;
    this.type2LinksWithValidatedField.filters = { validatedField: this.type1Base.id } as FilterObject;
    this.type2LinksWithArrayField.filters = { arrayField: this.type1Base.id } as FilterObject;
    this.type13CodesWithBase.filters = { base: this.type1Base.id } as FilterObject;
    this.type13CodesWithBaseArray.filters = { baseArray: this.type1Base.id } as FilterObject;
  }

  async reloadTables() {
    this.tab.title = `Type1Base at ID:${this.type1Base?.id}`;
    await this.type2LinksWithSimpleField.init({});
    await this.type2LinksWithValidatedField.init({});
    await this.type2LinksWithArrayField.init({});
    await this.type13CodesWithBase.init({});
    await this.type13CodesWithBaseArray.init({});
  }

  createClickHandleForType2LinkSimpleField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          simpleField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType2LinkValidatedField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          validatedField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType2LinkArrayField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          arrayField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType13CodeBase() {
    return this.pageService.createOpenObject(
      "SpecApp/Type13CodePage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type13Code at ID:${object ? object.id : null}`,
      () => {
        return {
          base: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType13CodeBaseArray() {
    return this.pageService.createOpenObject(
      "SpecApp/Type13CodePage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type13Code at ID:${object ? object.id : null}`,
      () => {
        return {
          baseArray: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }
}
