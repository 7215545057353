import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type1Base, Type1BaseType } from "./Type1Base";
import { Type1BaseTable } from "./type1Base.table";

@Component({
    selector: "spec-app-type1-base-select",
    templateUrl: "./type1Base.select.html",
    styleUrls: ["./type1Base.select.scss"]
})
export class Type1BaseSelect extends SelectWindow<Type1Base> {

    @ViewChild("table", { static: false }) table: Type1BaseTable;

    constructor(modalService: ModalService) {
        super(modalService, Type1BaseType, "name");
    }
}
