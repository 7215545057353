import { Type2Link, Type2LinkType } from "./Type2Link";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";
import { Type1BaseType } from "../type1base/Type1Base";
import { Type10MixedType } from "../type10mixed/Type10Mixed";

@Component({
    selector: "spec-app-type2-link-table",
    styleUrls: ["./type2Link.table.scss"],
    templateUrl: "./type2Link.table.html"
})
export class Type2LinkTable extends PagingTableComponent<Type2Link> {

    @Input() title: string;

    Type2Link = Type2Link;


    type1BasesForSimpleField = [];

    type1BasesForValidatedField = [];

    type1BasesForArrayField = [];

    type10MixedsForMixed = [];

    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type2LinkType, Type2Link.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        await this.loadTargetsForSimpleField();
        await this.loadTargetsForValidatedField();
        await this.loadTargetsForArrayField();
        await this.loadTargetsForMixed();
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    async loadTargetsForSimpleField() {
        let type1BaseDataService = this.tab.getService(Type1BaseType);
        this.type1BasesForSimpleField = (await type1BaseDataService.list({}, "loadTargetsForSimpleField" + this.id)).page;
    }

    async loadTargetsForValidatedField() {
        let type1BaseDataService = this.tab.getService(Type1BaseType);
        this.type1BasesForValidatedField = (await type1BaseDataService.list({}, "loadTargetsForValidatedField" + this.id)).page;
    }

    async loadTargetsForArrayField() {
        let type1BaseDataService = this.tab.getService(Type1BaseType);
        this.type1BasesForArrayField = (await type1BaseDataService.list({}, "loadTargetsForArrayField" + this.id)).page;
    }

    async loadTargetsForMixed() {
        let type10MixedDataService = this.tab.getService(Type10MixedType);
        this.type10MixedsForMixed = (await type10MixedDataService.list({}, "loadTargetsForMixed" + this.id)).page;
    }

    getClasses(type2Link) {
        let classes: any = { objects: true };
        return classes;
    }
}
