import { Type9Specials, Type9SpecialsType } from "./Type9Specials";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type9-specials-table",
    styleUrls: ["./type9Specials.table.scss"],
    templateUrl: "./type9Specials.table.html"
})
export class Type9SpecialsTable extends PagingTableComponent<Type9Specials> {

    @Input() title: string;

    Type9Specials = Type9Specials;


    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type9SpecialsType, Type9Specials.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type9Specials) {
        let classes: any = { objects: true };
        return classes;
    }
}
