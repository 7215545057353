import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type3Number, Type3NumberType } from "./Type3Number";
import { Type3NumberTable } from "./type3Number.table";

@Component({
    selector: "spec-app-type3-number-select",
    templateUrl: "./type3Number.select.html",
    styleUrls: ["./type3Number.select.scss"]
})
export class Type3NumberSelect extends SelectWindow<Type3Number> {

    @ViewChild("table", { static: false }) table: Type3NumberTable;

    constructor(modalService: ModalService) {
        super(modalService, Type3NumberType, "simpleField");
    }
}
