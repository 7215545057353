import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type10Mixed, Type10MixedType } from "./Type10Mixed";
import { Type10MixedService } from "./type10Mixed.service";
import { Type10MixedComponent } from "./type10Mixed.component";
import { createType10MixedValidators } from "./Type10MixedValidation";

@Component({
  selector: "spec-app-type10-mixed-page",
  templateUrl: "./type10Mixed-page.component.html",
  styleUrls: ["./type10Mixed-page.component.scss"]
})
export class Type10MixedPage extends ObjectWindow<Type10Mixed, Type10MixedService> {

  @ViewChild("component", { static: false }) component: Type10MixedComponent;

  Type10Mixed = Type10Mixed;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type10MixedType) as Type10MixedService, toastrService, createType10MixedValidators(), changeDetectorRef);
  }

  setupNewObject(rawType10Mixed) {
    let type10Mixed = Object.assign(new Type10Mixed(), rawType10Mixed);
    return type10Mixed;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
