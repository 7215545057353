import { ObjectReference, Node, ColumnInfo } from "@xo/services";


export class Type14AIReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type14AIType = "Type14AI";

export class Type14AI extends Node {

    constructor() {
        super(Type14AIType, "SpecApp/SimpleType14AIComponent");
    }

    get label() {
        return this.param1;
    }

    set label(label) {
        this.param1 = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Param 1", "param1", "string", "None"),
        new ColumnInfo("Param 2", "param2", "string", "None"),
    ];

    public id: number;
    public param1: string;
    public param2: string;
    public enchanced1: string;
    public enchanced2: string;
}
