import { DataService, CachingDataService, Transformator, ApiClient, transformDateFromServer, transformDateToServer, transformDateArrayFromServer, transformDateArrayToServer } from "@xo/services";
import { Type6DateUrlService } from "./type6date.url.service";
import { Type6Date, valueListFieldValues, valueListArrayValues } from "./Type6Date";
import { createValueListResolver } from "@xo/client-common";

export class Type6DateTransformator extends Transformator<Type6Date> {

    async transformFromServer(rawType6Date: any, localType6Date?: Type6Date): Promise<Type6Date> {
        let result: Type6Date = localType6Date ? localType6Date : new Type6Date();
        result = Object.assign(result, rawType6Date);
        result.simpleField = transformDateFromServer(rawType6Date.simpleField);
        result.valueListField = createValueListResolver(valueListFieldValues)(transformDateFromServer(rawType6Date.valueListField));
        result.validatedField = transformDateFromServer(rawType6Date.validatedField);
        result.arrayField = transformDateArrayFromServer(rawType6Date.arrayField);
        result.valueListArray = transformDateArrayFromServer(rawType6Date.valueListArray).map(createValueListResolver(valueListArrayValues));
        result.validatedArrayField = transformDateArrayFromServer(rawType6Date.validatedArrayField);
        return result;
    }

    transformToServer(type6Date: Type6Date): any {
        let result: any = Object.assign({}, type6Date);
        result.simpleField = transformDateToServer(type6Date.simpleField);
        result.valueListField = transformDateToServer(type6Date.valueListField);
        result.validatedField = transformDateToServer(type6Date.validatedField);
        result.arrayField = transformDateArrayToServer(type6Date.arrayField);
        result.valueListArray = transformDateArrayToServer(type6Date.valueListArray);
        result.validatedArrayField = transformDateArrayToServer(type6Date.validatedArrayField);
        return result;
    }
}

export class Type6DateService extends DataService<Type6Date> {

    constructor(http: ApiClient, type6DateUrlService: Type6DateUrlService, type6DateTransformator: Type6DateTransformator) {
        super(http, type6DateUrlService, type6DateTransformator, "id");
    }
}

export class CachingType6DateService extends CachingDataService<Type6Date> {

    constructor(http: ApiClient, type6DateUrlService: Type6DateUrlService, type6DateTransformator: Type6DateTransformator) {
        super(false, http, type6DateUrlService, type6DateTransformator, "id");
    }
}
