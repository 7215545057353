import { ObjectReference, Node, ColumnInfo } from "@xo/services";
import { Type1BaseReference } from "../type1base/Type1Base";


export class Type13CodeReference extends ObjectReference {
    constructor(id?: string, label?: string) {
        super(id, label, "code");
    }
}

export let Type13CodeType = "Type13Code";

export class Type13Code extends Node {

    constructor() {
        super(Type13CodeType, "SpecApp/SimpleType13CodeComponent");
    }

    get id() {
        return this.code;
    }

    set id(id) {
        this.code = id;
    }

    get label() {
        return this.head1;
    }

    set label(label) {
        this.head1 = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Code", "code", "string", "None"),
        new ColumnInfo("Head 1", "head1", "string", "None"),
        new ColumnInfo("Head 2", "head2", "string", "None"),
        new ColumnInfo("String Array", "stringArray", "string", "None"),
        new ColumnInfo("Date Array", "dateArray", "Date", "None"),
        new ColumnInfo("base", "base", "stringLink", "None"),
        new ColumnInfo("baseArray", "baseArray", "stringLink", "None"),
    ];

    public code: string;
    public head1: string;
    public head2: string;
    public body1: string;
    public body2: string;
    public stringArray: string[] = [];
    public dateArray: Date[] = [];
    public base: Type1BaseReference;
    public baseArray: Type1BaseReference[] = [];
}
