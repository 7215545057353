import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type12RichText, Type12RichTextType } from "./Type12RichText";
import { Type12RichTextTable } from "./type12RichText.table";

@Component({
    selector: "spec-app-type12-rich-text-select",
    templateUrl: "./type12RichText.select.html",
    styleUrls: ["./type12RichText.select.scss"]
})
export class Type12RichTextSelect extends SelectWindow<Type12RichText> {

    @ViewChild("table", { static: false }) table: Type12RichTextTable;

    constructor(modalService: ModalService) {
        super(modalService, Type12RichTextType, "simpleField");
    }
}
