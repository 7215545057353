import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type9Specials, Type9SpecialsType } from "./Type9Specials";
import { Type9SpecialsService } from "./type9Specials.service";
import { Type9SpecialsComponent } from "./type9Specials.component";
import { createType9SpecialsValidators } from "./Type9SpecialsValidation";

@Component({
  selector: "spec-app-type9-specials-page",
  templateUrl: "./type9Specials-page.component.html",
  styleUrls: ["./type9Specials-page.component.scss"]
})
export class Type9SpecialsPage extends ObjectWindow<Type9Specials, Type9SpecialsService> {

  @ViewChild("component", { static: false }) component: Type9SpecialsComponent;

  Type9Specials = Type9Specials;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type9SpecialsType) as Type9SpecialsService, toastrService, createType9SpecialsValidators(), changeDetectorRef);
  }

  setupNewObject(rawType9Specials) {
    let type9Specials = Object.assign(new Type9Specials(), rawType9Specials);
    return type9Specials;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
