import { ObjectReference, Node, ColumnInfo } from "@xo/services";


export class Type9SpecialsReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type9SpecialsType = "Type9Specials";

export class Type9Specials extends Node {

    constructor() {
        super(Type9SpecialsType, "SpecApp/SimpleType9SpecialsComponent");
    }

    get label() {
        return this.emailField;
    }

    set label(label) {
        this.emailField = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Email Field", "emailField", "string", "None"),
        new ColumnInfo("Email Array", "emailArray", "string", "None"),
        new ColumnInfo("Password Field", "passwordField", "string", "None"),
        new ColumnInfo("Password Array", "passwordArray", "string", "None"),
        new ColumnInfo("Phone Field", "phoneField", "string", "None"),
        new ColumnInfo("Phone Array", "phoneArray", "string", "None"),
    ];

    public id: number;
    public emailField: string;
    public emailArray: string[] = [];
    public passwordField: string;
    public passwordArray: string[] = [];
    public phoneField: string;
    public phoneArray: string[] = [];
}
