import { Component } from "@angular/core";
import { createValidate, ValidationMessage } from "@xo/client-common";
import { UserDashboard } from "./UserDashboard";

@Component({
  selector: "specapp-user-component",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"]
})
export class UserDashBoardComponent {

  public validate: Function;
  public validationMessages: ValidationMessage[] = [];

  constructor() {
    this.validate = createValidate<UserDashboard>([], this.validationMessages);
  }
}
