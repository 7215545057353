import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type13Code } from "./Type13Code";
import { ModalService } from "@xo/client-common";

@Component({
  selector: "spec-app-type13-code",
  templateUrl: "./type13Code.component.html",
  styleUrls: ["./type13Code.component.scss"]
})
export class Type13CodeComponent extends ObjectComponent {

  public Type13Code = Type13Code;
  @Input() public type13Code: Type13Code;
  @Input() public title: string;


  constructor(public modalService: ModalService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }
}
