import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type14AI } from "./Type14AI";

@Component({
  selector: "spec-app-type14-a-i",
  templateUrl: "./type14AI.component.html",
  styleUrls: ["./type14AI.component.scss"]
})
export class Type14AIComponent extends ObjectComponent {

  public Type14AI = Type14AI;
  @Input() public type14AI: Type14AI;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  ngOnInit() {
  }
}
