import { Type13Code, Type13CodeType } from "./Type13Code";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";
import { DatePipe } from "@angular/common";
import { Type1BaseType } from "../type1base/Type1Base";

@Component({
    selector: "spec-app-type13-code-table",
    styleUrls: ["./type13Code.table.scss"],
    templateUrl: "./type13Code.table.html"
})
export class Type13CodeTable extends PagingTableComponent<Type13Code> {

    @Input() title: string;

    Type13Code = Type13Code;


    type1BasesForBase = [];

    type1BasesForBaseArray = [];

    constructor(
        private datePipe: DatePipe,
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type13CodeType, Type13Code.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        await this.loadTargetsForBase();
        await this.loadTargetsForBaseArray();
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    dateArrayFilterChanged(event) {
        if (event.value) {
            this.filters["dateArray"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["dateArray"];
        }
        this.reload();
    }

    async loadTargetsForBase() {
        let type1BaseDataService = this.tab.getService(Type1BaseType);
        this.type1BasesForBase = (await type1BaseDataService.list({}, "loadTargetsForBase" + this.id)).page;
    }

    async loadTargetsForBaseArray() {
        let type1BaseDataService = this.tab.getService(Type1BaseType);
        this.type1BasesForBaseArray = (await type1BaseDataService.list({}, "loadTargetsForBaseArray" + this.id)).page;
    }

    getClasses(type13Code) {
        let classes: any = { objects: true };
        return classes;
    }
}
