import { createMaxValueValidator, createMinValueValidator, createRequiredValidators } from "@xo/client-common";
import { Type3Number } from "./Type3Number";

export function createType3NumberValidators() {
    return [
        ...createRequiredValidators<Type3Number>(["simpleField", "valueListField", "validatedField", "arrayField", "valueListArray", "validatedArrayField"]),
        createMaxValueValidator<Type3Number>(100, "validatedField"),
        createMinValueValidator<Type3Number>(10, "validatedField")
    ];
}
