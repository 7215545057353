import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { transformDateToServer } from "@xo/services";
import { Type6Date, Type6DateType } from "./Type6Date";
import { Type6DateTable } from "./type6Date.table";

@Component({
    selector: "spec-app-type6-date-select",
    templateUrl: "./type6Date.select.html",
    styleUrls: ["./type6Date.select.scss"]
})
export class Type6DateSelect extends SelectWindow<Type6Date> {

    @ViewChild("table", { static: false }) table: Type6DateTable;

    constructor(modalService: ModalService) {
        super(modalService, Type6DateType, "simpleField");
    }

    formatLabel(label) {
        return transformDateToServer(label);
    }

}
