import { Component, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { AdminDashboard, AdminDashboardType } from "./AdminDashboard";
import { AdminDashboardService } from "./AdminDashboard.service";

@Component({
  selector: "specapp-admin-dashboard",
  templateUrl: "./admin-dashboard.page.html",
  styleUrls: ["./admin-dashboard.page.scss"]
})
export class AdminDashBoardPage extends ObjectWindow<AdminDashboard, AdminDashboardService> {

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(AdminDashboardType), toastrService, [], changeDetectorRef);
  }

  setupNewObject(rawConfig) {
    let config = Object.assign(new AdminDashboard(), rawConfig);
    return config;
  }
}
