import { DataService, CachingDataService, Transformator, ApiClient, transformDateFromServer, transformDateToServer, transformDateArrayFromServer, transformDateArrayToServer, transformTimestampToServer, transformTimestampArrayToServer } from "@xo/services";
import { Type10MixedUrlService } from "./type10mixed.url.service";
import { Type10Mixed } from "./Type10Mixed";

export class Type10MixedTransformator extends Transformator<Type10Mixed> {

    async transformFromServer(rawType10Mixed: any, localType10Mixed?: Type10Mixed): Promise<Type10Mixed> {
        let result: Type10Mixed = localType10Mixed ? localType10Mixed : new Type10Mixed();
        result = Object.assign(result, rawType10Mixed);
        if (!result.stringArrayField) {
            result.stringArrayField = [];
        }
        if (!result.longStringArrayField) {
            result.longStringArrayField = [];
        }
        if (!result.numberArrayField) {
            result.numberArrayField = [];
        }
        if (!result.fractionArrayField) {
            result.fractionArrayField = [];
        }
        if (!result.booleanArrayField) {
            result.booleanArrayField = [];
        }
        result.dateField = transformDateFromServer(rawType10Mixed.dateField);
        result.dateArrayField = transformDateArrayFromServer(rawType10Mixed.dateArrayField);
        if (!result.timeArrayField) {
            result.timeArrayField = [];
        }
        result.timestampField = transformDateFromServer(rawType10Mixed.timestampField);
        result.timestampArrayField = transformDateArrayFromServer(rawType10Mixed.timestampArrayField);
        return result;
    }

    transformToServer(type10Mixed: Type10Mixed): any {
        let result: any = Object.assign({}, type10Mixed);
        result.dateField = transformDateToServer(type10Mixed.dateField);
        result.dateArrayField = transformDateArrayToServer(type10Mixed.dateArrayField);
        result.timestampField = transformTimestampToServer(type10Mixed.timestampField);
        result.timestampArrayField = transformTimestampArrayToServer(type10Mixed.timestampArrayField);
        return result;
    }
}

export class Type10MixedService extends DataService<Type10Mixed> {

    constructor(http: ApiClient, type10MixedUrlService: Type10MixedUrlService, type10MixedTransformator: Type10MixedTransformator) {
        super(http, type10MixedUrlService, type10MixedTransformator, "id");
    }
}

export class CachingType10MixedService extends CachingDataService<Type10Mixed> {

    constructor(http: ApiClient, type10MixedUrlService: Type10MixedUrlService, type10MixedTransformator: Type10MixedTransformator) {
        super(false, http, type10MixedUrlService, type10MixedTransformator, "id");
    }
}
