import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type3NumberUrlService } from "./type3number.url.service";
import { Type3Number } from "./Type3Number";

export class Type3NumberTransformator extends Transformator<Type3Number> {

    async transformFromServer(rawType3Number: any, localType3Number?: Type3Number): Promise<Type3Number> {
        let result: Type3Number = localType3Number ? localType3Number : new Type3Number();
        result = Object.assign(result, rawType3Number);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type3Number: Type3Number): any {
        let result: any = Object.assign({}, type3Number);
        return result;
    }
}

export class Type3NumberService extends DataService<Type3Number> {

    constructor(http: ApiClient, type3NumberUrlService: Type3NumberUrlService, type3NumberTransformator: Type3NumberTransformator) {
        super(http, type3NumberUrlService, type3NumberTransformator, "id");
    }
}

export class CachingType3NumberService extends CachingDataService<Type3Number> {

    constructor(http: ApiClient, type3NumberUrlService: Type3NumberUrlService, type3NumberTransformator: Type3NumberTransformator) {
        super(false, http, type3NumberUrlService, type3NumberTransformator, "id");
    }
}
