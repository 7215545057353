import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type2LongStringUrlService } from "./type2longstring.url.service";
import { Type2LongString } from "./Type2LongString";

export class Type2LongStringTransformator extends Transformator<Type2LongString> {

    async transformFromServer(rawType2LongString: any, localType2LongString?: Type2LongString): Promise<Type2LongString> {
        let result: Type2LongString = localType2LongString ? localType2LongString : new Type2LongString();
        result = Object.assign(result, rawType2LongString);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type2LongString: Type2LongString): any {
        let result: any = Object.assign({}, type2LongString);
        return result;
    }
}

export class Type2LongStringService extends DataService<Type2LongString> {

    constructor(http: ApiClient, type2LongStringUrlService: Type2LongStringUrlService, type2LongStringTransformator: Type2LongStringTransformator) {
        super(http, type2LongStringUrlService, type2LongStringTransformator, "id");
    }
}

export class CachingType2LongStringService extends CachingDataService<Type2LongString> {

    constructor(http: ApiClient, type2LongStringUrlService: Type2LongStringUrlService, type2LongStringTransformator: Type2LongStringTransformator) {
        super(false, http, type2LongStringUrlService, type2LongStringTransformator, "id");
    }
}
