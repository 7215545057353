import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type7Time, Type7TimeType } from "./Type7Time";
import { Type7TimeTable } from "./type7Time.table";

@Component({
    selector: "spec-app-type7-time-select",
    templateUrl: "./type7Time.select.html",
    styleUrls: ["./type7Time.select.scss"]
})
export class Type7TimeSelect extends SelectWindow<Type7Time> {

    @ViewChild("table", { static: false }) table: Type7TimeTable;

    constructor(modalService: ModalService) {
        super(modalService, Type7TimeType, "simpleField");
    }
}
