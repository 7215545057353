import { ObjectReference, Node, ColumnInfo } from "@xo/services";

export const valueListFieldValues = new Map<string, string>();
valueListFieldValues.set("00:00", "midnight");
valueListFieldValues.set("12:00", "noon");
valueListFieldValues.set("23:59", "last minute");

export const valueListArrayValues = new Map<string, string>();
valueListArrayValues.set("00:00", "midnight");
valueListArrayValues.set("12:00", "noon");
valueListArrayValues.set("23:59", "last minute");


export class Type7TimeReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type7TimeType = "Type7Time";

export class Type7Time extends Node {

    constructor() {
        super(Type7TimeType, "SpecApp/SimpleType7TimeComponent");
    }

    get label() {
        return this.simpleField;
    }

    set label(label) {
        this.simpleField = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "string", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "stringSelect", "None"),
        new ColumnInfo("Validated Field", "validatedField", "string", "None"),
        new ColumnInfo("Array Field", "arrayField", "string", "None"),
        new ColumnInfo("ValueList Array", "valueListArray", "stringSelect", "None"),
        new ColumnInfo("Validated ArrayField", "validatedArrayField", "string", "None"),
    ];

    public static valueListFieldKeys: string[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: string): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: string[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: string): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: string;
    public valueListField: string;
    public validatedField: string;
    public arrayField: string[] = [];
    public valueListArray: string[] = [];
    public validatedArrayField: string[] = [];
}
