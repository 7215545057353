import { ObjectReference, Node, ColumnInfo } from "@xo/services";

export const valueListFieldValues = new Map<string, string>();
valueListFieldValues.set("Value1", "Value 1");
valueListFieldValues.set("Value2", "Value 2");
valueListFieldValues.set("Value3", "Value 3");

export const valueListArrayValues = new Map<string, string>();
valueListArrayValues.set("Value1", "Value 1");
valueListArrayValues.set("Value2", "Value 2");
valueListArrayValues.set("Value3", "Value 3");


export class Type1StringReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type1StringType = "Type1String";

export class Type1String extends Node {

    constructor() {
        super(Type1StringType, "SpecApp/SimpleType1StringComponent");
    }

    get label() {
        return this.simpleField;
    }

    set label(label) {
        this.simpleField = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "string", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "stringSelect", "None"),
        new ColumnInfo("Validated Field", "validatedField", "string", "None"),
        new ColumnInfo("Array Field", "arrayField", "string", "None"),
        new ColumnInfo("Value List Array", "valueListArray", "stringSelect", "None"),
        new ColumnInfo("Validated Array Field", "validatedArrayField", "string", "None"),
    ];

    public static valueListFieldKeys: string[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: string): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: string[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: string): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: string;
    public valueListField: string;
    public validatedField: string;
    public arrayField: string[] = [];
    public valueListArray: string[] = [];
    public validatedArrayField: string[] = [];
}
