import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator, createMaxLengthValidator, createMinLengthValidator, createExcludeValidator, createMinArrayLengthValidator, createMaxArrayValueLengthValidator, createMaxArrayLengthValidator, createMinArrayValueLengthValidator, createArrayExcludeValidator } from "@xo/client-common";
import { Type2LongString } from "./Type2LongString";

export function createType2LongStringValidators() {
    return [
        ...createRequiredValidators<Type2LongString>(["simpleField", "valueListField", "validatedField"]),
        createMaxLengthValidator("simpleField", 500),
        createMinLengthValidator("validatedField", 3),
        createMaxLengthValidator("validatedField", 16),
        createRegexpValidator('validatedField', /^[a-zA-Z]+$/, "Validated Field should contain only [a-zA-Z]+!"),
        createExcludeValidator("validatedField", ["wrong"]),
        createMinArrayLengthValidator("arrayField", 1),
        createMaxArrayValueLengthValidator("arrayField", 500),
        createMinArrayLengthValidator("valueListArray", 1),
        createMinArrayLengthValidator("validatedArrayField", 1),
        createMaxArrayLengthValidator("validatedArrayField", 3),
        createMinArrayValueLengthValidator("validatedArrayField", 3),
        createMaxArrayValueLengthValidator("validatedArrayField", 8),
        createArrayExcludeValidator("validatedArrayField", ["wrong"]),
        createRegexpArrayValidator('validatedArrayField', /^[a-zA-Z]+$/, "Validated Array Field should contain only [a-zA-Z]+!"),
    ];
}
