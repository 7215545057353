import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type14AI, Type14AIType } from "./Type14AI";
import { Type14AITable } from "./type14AI.table";

@Component({
    selector: "spec-app-type14-a-i-select",
    templateUrl: "./type14AI.select.html",
    styleUrls: ["./type14AI.select.scss"]
})
export class Type14AISelect extends SelectWindow<Type14AI> {

    @ViewChild("table", { static: false }) table: Type14AITable;

    constructor(modalService: ModalService) {
        super(modalService, Type14AIType, "param1");
    }
}
