import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type12RichTextUrlService } from "./type12richtext.url.service";
import { Type12RichText } from "./Type12RichText";

export class Type12RichTextTransformator extends Transformator<Type12RichText> {

    async transformFromServer(rawType12RichText: any, localType12RichText?: Type12RichText): Promise<Type12RichText> {
        let result: Type12RichText = localType12RichText ? localType12RichText : new Type12RichText();
        result = Object.assign(result, rawType12RichText);
        return result;
    }

    transformToServer(type12RichText: Type12RichText): any {
        let result: any = Object.assign({}, type12RichText);
        return result;
    }
}

export class Type12RichTextService extends DataService<Type12RichText> {

    constructor(http: ApiClient, type12RichTextUrlService: Type12RichTextUrlService, type12RichTextTransformator: Type12RichTextTransformator) {
        super(http, type12RichTextUrlService, type12RichTextTransformator, "id");
    }
}

export class CachingType12RichTextService extends CachingDataService<Type12RichText> {

    constructor(http: ApiClient, type12RichTextUrlService: Type12RichTextUrlService, type12RichTextTransformator: Type12RichTextTransformator) {
        super(false, http, type12RichTextUrlService, type12RichTextTransformator, "id");
    }
}
