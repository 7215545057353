import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ListWindow, PageService, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type3NumberTable } from "./type3Number.table";

@Component({
  selector: "spec-app-type3-number-list-window",
  templateUrl: "./type3Number.list.html",
  styleUrls: ["./type3Number.list.scss"]
})
export class Type3NumberListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type3NumberTable;

  clickHandler: Function;

  private tab;

  constructor(changeDetectorRef: ChangeDetectorRef, public pageService: PageService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(changeDetectorRef);
    this.tab = deckTab || tab;
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type3NumberPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type3Number at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters);
  }

  reload(filters) {
    console.log("Type3NumberList reload");
    super.reload(filters, false);
  }
}
