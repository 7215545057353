import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { OwlNativeDateTimeModule, OwlDateTimeModule } from "ng-pick-datetime";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { XoCommonModule } from "@xo/client-common";
import { declarations, exports, tabComponents } from "./specapp.service";

export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", hour12: false },
  datePickerInput: { year: "numeric", month: "numeric", day: "numeric" },
  timePickerInput: { hour: "2-digit", minute: "2-digit", hour12: false },
  monthYearLabel: { year: "numeric", month: "short" },
  dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
  monthYearA11yLabel: { year: "numeric", month: "long" },
};

@NgModule({
  declarations,
  imports: [
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    FormsModule,
    XoCommonModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
  ],
  providers: [
    DatePipe
  ],
  entryComponents: tabComponents,
  exports
})
export class SpecAppModule { }
