import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type13Code, Type13CodeType } from "./Type13Code";
import { Type13CodeService } from "./type13Code.service";
import { Type13CodeComponent } from "./type13Code.component";
import { createType13CodeValidators } from "./Type13CodeValidation";
import { ObjectReference } from "@xo/services";

@Component({
  selector: "spec-app-type13-code-page",
  templateUrl: "./type13Code-page.component.html",
  styleUrls: ["./type13Code-page.component.scss"]
})
export class Type13CodePage extends ObjectWindow<Type13Code, Type13CodeService> {

  @ViewChild("component", { static: false }) component: Type13CodeComponent;

  Type13Code = Type13Code;

  public newBaseArray: ObjectReference;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type13CodeType) as Type13CodeService, toastrService, createType13CodeValidators(), changeDetectorRef);
  }

  setupNewObject(rawType13Code) {
    let type13Code = Object.assign(new Type13Code(), rawType13Code);
    return type13Code;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
