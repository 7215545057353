import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type2LinkUrlService } from "./type2link.url.service";
import { Type2Link } from "./Type2Link";
import { Type1BaseReference } from "../type1base/Type1Base";
import { Type10MixedReference } from "../type10mixed/Type10Mixed";

export class Type2LinkTransformator extends Transformator<Type2Link> {

    async transformFromServer(rawType2Link: any, localType2Link?: Type2Link): Promise<Type2Link> {
        let result: Type2Link = localType2Link ? localType2Link : new Type2Link();
        result = Object.assign(result, rawType2Link);
        result.simpleField = rawType2Link.simpleField ? new Type1BaseReference(rawType2Link.simpleField.id, rawType2Link.simpleField.label) : null;
        result.validatedField = rawType2Link.validatedField ? new Type1BaseReference(rawType2Link.validatedField.id, rawType2Link.validatedField.label) : null;
        result.arrayField = rawType2Link.arrayField ?
            rawType2Link.arrayField.map(
                (arrayMember) => {
                    return new Type1BaseReference(arrayMember.id, arrayMember.label);
                })
            : [];
        return result;
    }

    transformToServer(type2Link: Type2Link): any {
        let result: any = Object.assign({}, type2Link);
        return result;
    }
}

export class Type2LinkService extends DataService<Type2Link> {

    constructor(http: ApiClient, type2LinkUrlService: Type2LinkUrlService, type2LinkTransformator: Type2LinkTransformator) {
        super(http, type2LinkUrlService, type2LinkTransformator, "id");
    }
}

export class CachingType2LinkService extends CachingDataService<Type2Link> {

    constructor(http: ApiClient, type2LinkUrlService: Type2LinkUrlService, type2LinkTransformator: Type2LinkTransformator) {
        super(false, http, type2LinkUrlService, type2LinkTransformator, "id");
    }
}
