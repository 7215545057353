import { Type8Timestamp, Type8TimestampType } from "./Type8Timestamp";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type8-timestamp-table",
    styleUrls: ["./type8Timestamp.table.scss"],
    templateUrl: "./type8Timestamp.table.html"
})
export class Type8TimestampTable extends PagingTableComponent<Type8Timestamp> {

    @Input() title: string;

    Type8Timestamp = Type8Timestamp;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type8TimestampType, Type8Timestamp.columnInfo, modalTab || tab || deckTab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type8Timestamp.valueListFieldKeys, Type8Timestamp.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type8Timestamp.valueListArrayKeys, Type8Timestamp.getValueListArrayValues);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type8Timestamp) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type8Timestamp.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type8Timestamp.getValueListArrayValues);
    }
}
