import { ConfigService, SystemUrlService } from "@xo/client-common";
import { Type1Base } from "./Type1Base";

export class Type1BaseUrlService extends SystemUrlService {
    constructor(system: string, configService: ConfigService) {
        super(system, "SpecApp", "Type1Base", configService);
    }

    getActionUrl(): string {
        return `${this.getListUrl()}/action`;
    }

    getCreateDocumentUrl(type1Base: Type1Base): string {
        return `${this.getLoadUrl(type1Base.id)}/createDocument`;
    }

    getStreamUrl(type1Base: Type1Base): string {
        return `${this.getLoadUrl(type1Base.id)}/stream`;
    }

    getDownloadUrl(type1Base: Type1Base, documentId: string): string {
        return `${this.getLoadUrl(type1Base.id)}/download/${documentId}`;
    }
}
