import { ApiClient, DataService, Transformator } from "@xo/services";
import { ConfigService, SystemUrlService } from "@xo/client-common";
import { AdminDashboard } from "./AdminDashboard";

export class AdminDashboardUrlService extends SystemUrlService {
  constructor(system: string, configService: ConfigService) {
    super(system, "SpecApp", "AdminDashboard", configService);
  }
}

export class AdminDashboardTransformator extends Transformator<AdminDashboard> {

  async transformFromServer(rawConfig: any, localConfig?: AdminDashboard): Promise<AdminDashboard> {
    let result: AdminDashboard = localConfig ? localConfig : new AdminDashboard();
    result = Object.assign(result, rawConfig);
    return result;
  }

  transformToServer(config: AdminDashboard): any {
    let result: any = Object.assign({}, config);
    return result;
  }
}

export class AdminDashboardService extends DataService<AdminDashboard> {

  constructor(http: ApiClient, configUrlService: AdminDashboardUrlService, configTransformator: AdminDashboardTransformator) {
    super(http, configUrlService, configTransformator, "id");
  }
}
