import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ListWindow, PageService, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type4FractionTable } from "./type4Fraction.table";

@Component({
  selector: "spec-app-type4-fraction-list-window",
  templateUrl: "./type4Fraction.list.html",
  styleUrls: ["./type4Fraction.list.scss"]
})
export class Type4FractionListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type4FractionTable;

  clickHandler: Function;

  private tab;

  constructor(changeDetectorRef: ChangeDetectorRef, public pageService: PageService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(changeDetectorRef);
    this.tab = deckTab || tab;
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type4FractionPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type4Fraction at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters);
  }

  reload(filters) {
    console.log("Type4FractionList reload");
    super.reload(filters, false);
  }
}
