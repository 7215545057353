import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type8Timestamp, Type8TimestampType } from "./Type8Timestamp";
import { Type8TimestampService } from "./type8Timestamp.service";
import { Type8TimestampComponent } from "./type8Timestamp.component";
import { createType8TimestampValidators } from "./Type8TimestampValidation";

@Component({
  selector: "spec-app-type8-timestamp-page",
  templateUrl: "./type8Timestamp-page.component.html",
  styleUrls: ["./type8Timestamp-page.component.scss"]
})
export class Type8TimestampPage extends ObjectWindow<Type8Timestamp, Type8TimestampService> {

  @ViewChild("component", { static: false }) component: Type8TimestampComponent;

  Type8Timestamp = Type8Timestamp;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type8TimestampType) as Type8TimestampService, toastrService, createType8TimestampValidators(), changeDetectorRef);
  }

  setupNewObject(rawType8Timestamp) {
    let type8Timestamp = Object.assign(new Type8Timestamp(), rawType8Timestamp);
    return type8Timestamp;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
