import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ListWindow, PageService, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type6DateTable } from "./type6Date.table";

@Component({
  selector: "spec-app-type6-date-list-window",
  templateUrl: "./type6Date.list.html",
  styleUrls: ["./type6Date.list.scss"]
})
export class Type6DateListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type6DateTable;

  clickHandler: Function;

  private tab;

  constructor(changeDetectorRef: ChangeDetectorRef, public pageService: PageService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(changeDetectorRef);
    this.tab = deckTab || tab;
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type6DatePage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type6Date at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters);
  }

  reload(filters) {
    console.log("Type6DateList reload");
    super.reload(filters, false);
  }
}
