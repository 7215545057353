import { Component, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { UserDashboard, UserDashboardType } from "./UserDashboard";
import { UserDashboardService } from "./UserDashboard.service";

@Component({
  selector: "specapp-user-dashboard",
  templateUrl: "./user-dashboard.page.html",
  styleUrls: ["./user-dashboard.page.scss"]
})
export class UserDashBoardPage extends ObjectWindow<UserDashboard, UserDashboardService> {

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(UserDashboardType), toastrService, [], changeDetectorRef);
  }

  setupNewObject(rawConfig) {
    let config = Object.assign(new UserDashboard(), rawConfig);
    return config;
  }
}
