import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type9Specials } from "./Type9Specials";

@Component({
  selector: "spec-app-type9-specials",
  templateUrl: "./type9Specials.component.html",
  styleUrls: ["./type9Specials.component.scss"]
})
export class Type9SpecialsComponent extends ObjectComponent {

  public Type9Specials = Type9Specials;
  @Input() public type9Specials: Type9Specials;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }
}
