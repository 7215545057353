import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type8Timestamp } from "./Type8Timestamp";

@Component({
  selector: "spec-app-type8-timestamp",
  templateUrl: "./type8Timestamp.component.html",
  styleUrls: ["./type8Timestamp.component.scss"]
})
export class Type8TimestampComponent extends ObjectComponent {

  public Type8Timestamp = Type8Timestamp;
  @Input() public type8Timestamp: Type8Timestamp;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }
}
