import { ObjectReference, Node, ColumnInfo } from "@xo/services";

export const valueListFieldValues = new Map<number, string>();
valueListFieldValues.set(1.1, "1.1");
valueListFieldValues.set(2.2, "2.2");
valueListFieldValues.set(3.3, "3.3");

export const valueListArrayValues = new Map<number, string>();
valueListArrayValues.set(1.1, "1.1");
valueListArrayValues.set(2.2, "2.2");
valueListArrayValues.set(3.3, "3.3");


export class Type4FractionReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type4FractionType = "Type4Fraction";

export class Type4Fraction extends Node {

    constructor() {
        super(Type4FractionType, "SpecApp/SimpleType4FractionComponent");
    }

    get label() {
        return "" + this.simpleField;
    }

    set label(label) {
        this.simpleField = parseFloat(label);
        if (this.simpleField === NaN) {
            this.simpleField = null;
        }
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "number", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "number", "None"),
        new ColumnInfo("Validated Field", "validatedField", "number", "None"),
        new ColumnInfo("Array Field", "arrayField", "number", "None"),
        new ColumnInfo("ValueList Array", "valueListArray", "number", "None"),
        new ColumnInfo("Validated Array Field", "validatedArrayField", "number", "None"),
    ];

    public static valueListFieldKeys: number[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: number): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: number[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: number): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: number;
    public valueListField: number;
    public validatedField: number;
    public arrayField: number[] = [];
    public valueListArray: number[] = [];
    public validatedArrayField: number[] = [];
}
