import { ObjectReference, Node, ColumnInfo } from "@xo/services";


export class Type12RichTextReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type12RichTextType = "Type12RichText";

export class Type12RichText extends Node {

    constructor() {
        super(Type12RichTextType, "SpecApp/SimpleType12RichTextComponent");
    }

    get label() {
        return this.simpleField;
    }

    set label(label) {
        this.simpleField = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "string", "None"),
        new ColumnInfo("Validated Field", "validatedField", "string", "None"),
    ];

    public id: number;
    public simpleField: string;
    public validatedField: string;
}
