import { Component, Input, OnInit, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type12RichText } from "./Type12RichText";

@Component({
  selector: "spec-app-type12-rich-text",
  templateUrl: "./type12RichText.component.html",
  styleUrls: ["./type12RichText.component.scss"]
})
export class Type12RichTextComponent extends ObjectComponent {

  public Type12RichText = Type12RichText;
  @Input() public type12RichText: Type12RichText;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }
}
