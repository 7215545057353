import { Type14AI, Type14AIType } from "./Type14AI";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type14-a-i-table",
    styleUrls: ["./type14AI.table.scss"],
    templateUrl: "./type14AI.table.html"
})
export class Type14AITable extends PagingTableComponent<Type14AI> {

    @Input() title: string;

    Type14AI = Type14AI;


    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type14AIType, Type14AI.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type14AI) {
        let classes: any = { objects: true };
        return classes;
    }
}
