import { Component } from "@angular/core";
import { createValidate, ValidationMessage } from "@xo/client-common";
import { SystemDashboard } from "./SystemDashboard";

@Component({
  selector: "specapp-system-component",
  templateUrl: "./system-dashboard.component.html",
  styleUrls: ["./system-dashboard.component.scss"]
})
export class SystemDashBoardComponent {

  public validate: Function;
  public validationMessages: ValidationMessage[] = [];

  constructor() {
    this.validate = createValidate<SystemDashboard>([], this.validationMessages);
  }
}
