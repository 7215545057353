import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type4FractionUrlService } from "./type4fraction.url.service";
import { Type4Fraction } from "./Type4Fraction";

export class Type4FractionTransformator extends Transformator<Type4Fraction> {

    async transformFromServer(rawType4Fraction: any, localType4Fraction?: Type4Fraction): Promise<Type4Fraction> {
        let result: Type4Fraction = localType4Fraction ? localType4Fraction : new Type4Fraction();
        result = Object.assign(result, rawType4Fraction);
        if (!result.arrayField) {
            result.arrayField = [];
        }
        if (!result.valueListArray) {
            result.valueListArray = [];
        }
        if (!result.validatedArrayField) {
            result.validatedArrayField = [];
        }
        return result;
    }

    transformToServer(type4Fraction: Type4Fraction): any {
        let result: any = Object.assign({}, type4Fraction);
        return result;
    }
}

export class Type4FractionService extends DataService<Type4Fraction> {

    constructor(http: ApiClient, type4FractionUrlService: Type4FractionUrlService, type4FractionTransformator: Type4FractionTransformator) {
        super(http, type4FractionUrlService, type4FractionTransformator, "id");
    }
}

export class CachingType4FractionService extends CachingDataService<Type4Fraction> {

    constructor(http: ApiClient, type4FractionUrlService: Type4FractionUrlService, type4FractionTransformator: Type4FractionTransformator) {
        super(false, http, type4FractionUrlService, type4FractionTransformator, "id");
    }
}
