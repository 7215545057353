import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from "@angular/core";
import { AppComponent } from "./app.component";
import { XoCommonModule, MenuService, ConfigService, routes, TOKEN_EXPORT_TYPES } from "@xo/client-common";
import { ModalDialogModule } from "@preeco-privacy/ngx-modal-dialog";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { ToastContainerModule } from "ngx-toastr";
import { RouterModule } from "@angular/router";
import { SpecAppMenuService } from "./specapp.menu.service";
import { SpecAppModule } from "projects/specapp/src/specapp.module";
import { createSpecAppServiceFactory } from "../../../specapp/src/specapp.service";
import { moduleExports } from "./exports";

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true, paramsInheritanceStrategy: "always" }),
    ToastContainerModule,
    HttpClientModule,
    ModalDialogModule.forRoot(),
    XoCommonModule,
    SpecAppModule
  ],
  providers: [
    { provide: TOKEN_EXPORT_TYPES, useValue: moduleExports },
    { provide: MenuService, useClass: SpecAppMenuService },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "hu" },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, http: HttpClient) => async () => { await init(configService, http); },
      deps: [ConfigService, HttpClient],
      multi: true
    },
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

async function init(configService: ConfigService, http: HttpClient) {
  await configService.load();

  configService.serviceFactories.set("SpecApp", createSpecAppServiceFactory(configService, http));
}
