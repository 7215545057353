import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type1String, Type1StringType } from "./Type1String";
import { Type1StringTable } from "./type1String.table";

@Component({
    selector: "spec-app-type1-string-select",
    templateUrl: "./type1String.select.html",
    styleUrls: ["./type1String.select.scss"]
})
export class Type1StringSelect extends SelectWindow<Type1String> {

    @ViewChild("table", { static: false }) table: Type1StringTable;

    constructor(modalService: ModalService) {
        super(modalService, Type1StringType, "simpleField");
    }
}
