import { Component, ViewChild } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type11File, Type11FileType } from "./Type11File";
import { Type11FileTable } from "./type11File.table";

@Component({
    selector: "spec-app-type11-file-select",
    templateUrl: "./type11File.select.html",
    styleUrls: ["./type11File.select.scss"]
})
export class Type11FileSelect extends SelectWindow<Type11File> {

    @ViewChild("table", { static: false }) table: Type11FileTable;

    constructor(modalService: ModalService) {
        super(modalService, Type11FileType, "simpleFile", "code");
    }
}
