import { Type12RichText, Type12RichTextType } from "./Type12RichText";
import { Component, Input, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent, DeckTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type12-rich-text-table",
    styleUrls: ["./type12RichText.table.scss"],
    templateUrl: "./type12RichText.table.html"
})
export class Type12RichTextTable extends PagingTableComponent<Type12RichText> {

    @Input() title: string;

    Type12RichText = Type12RichText;


    constructor(
        @Optional() modalTab?: ModalTabComponent, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
        super(Type12RichTextType, Type12RichText.columnInfo, modalTab || tab || deckTab);
    }

    async init(dinamicFilters?: any, reload?: boolean, pageIndex?: number, reAddPaging?: boolean) {
        super.init(dinamicFilters, reload, pageIndex, reAddPaging);
    }

    getClasses(type12RichText) {
        let classes: any = { objects: true };
        return classes;
    }
}
