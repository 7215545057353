import { ObjectReference, Node, ColumnInfo, transformDateToServer } from "@xo/services";

export const valueListFieldValues = new Map<Date, string>();
valueListFieldValues.set(new Date("2018-01-01"), "First of January");
valueListFieldValues.set(new Date("2018-12-31"), "Silvester");
valueListFieldValues.set(new Date("2018-06-15"), "Mid June");

export const valueListArrayValues = new Map<Date, string>();
valueListArrayValues.set(new Date("2018-01-01"), "First of January");
valueListArrayValues.set(new Date("2018-12-31"), "Silvester");
valueListArrayValues.set(new Date("2018-06-15"), "Mid June");


export class Type6DateReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type6DateType = "Type6Date";

export class Type6Date extends Node {

    constructor() {
        super(Type6DateType, "SpecApp/SimpleType6DateComponent");
    }

    get label() {
        return transformDateToServer(this.simpleField);
    }

    set label(label) {
        this.simpleField = new Date(label);
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "Date", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "Date", "None"),
        new ColumnInfo("Validated Field", "validatedField", "Date", "None"),
        new ColumnInfo("Array Field", "arrayField", "Date", "None"),
        new ColumnInfo("ValueList Array", "valueListArray", "Date", "None"),
        new ColumnInfo("Validated Array Field", "validatedArrayField", "Date", "None"),
    ];

    public static valueListFieldKeys: Date[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: Date): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: Date[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: Date): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: Date;
    public valueListField: Date;
    public validatedField: Date;
    public arrayField: Date[] = [];
    public valueListArray: Date[] = [];
    public validatedArrayField: Date[] = [];
}
